.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;

  @include media-breakpoint-down(md) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.container-fluid.full {
  padding-right: 0;
  padding-left: 0;
}

.mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.dropdown-item {
  &:active,
  &.active {
    color: #3d5056;
    font-weight: 600;
    text-decoration: none;
    background-color: #f1f1f1;
  }
}

.accordion-button.collapsed::after {

  background: url("../../assets/plus.svg");

}

.accordion-button:not(.collapsed)::after {

  background: url("../../assets/minus.svg");

}
