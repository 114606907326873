@import "../global.scss";

$grey4e: #4e4e4e;
$grey65: #656565;
$greyc7: #c7c7c7;
$greye8: #e8e8e8;
$greyd8: #d8d8d8;
$greyd6: #d6d6d6;
$greydc: #dcdcdc;
$greya1: #a1a1a1;
$greyf1: #f1f1f1;
$greyf2: #f2f2f2;
$grey97: #979797;
$grey8f: #8fa0a5;
$grey8f8: #8f8f8f;
$grey6d: #6d8086;
$greyf7: #f7f7f7;
$greyf6: #f6f6f6;
$greyf5: #f5f5f7;
$greyfa: #fafbfd;
$greye4: #e4e4e4;
$greye9: #e9e9e9;
$grey20: #202124;
$grey3d: #3d5056;
$grey3b: #3b3b3b;
$grey1c: #1c1c1c;
$grey03: #030303;

$black: #1a1a1a;
$white: #ffffff;

$blue23: #2346aa;
$blue2c: #2c5de5;
$blue26: #2648a4;
$blue39: #3989c9;
$blueee: #eef3ff;
$blue0c: #0c64ce;
$blueaa: #aac4cc;
$blue91: #91adfa;

$redde: #de1c22;
$redff: #ff524e;
$redf9: #f9d3d4;

$green00: #008556;
$greend6: #d6f3e2;

$yellowff: #ffe1be;

$orangee2: #e2975d;
$orangefc: #fc9100;

$colors: #7f1818, #ef2629, #ff6634, #fc9100, #f7d74f, #aa7830, #960087, #a600c8,
  #e95ded, #e184f4, #ed7caa, #7951f4, #11498a, #154be5, #3989c9, #4f7ef7,
  #69b3e3, #40b2f2, #006e79, #197b30, #5fc96b, #63717c, #b1b6ba, #ed4087;

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis-lines($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}
